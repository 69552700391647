import { useQuery } from '@apollo/client';
import { Icon, Separator, Stack, Text } from '@fluentui/react';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { StyledLink } from 'common/components/StyledLink';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalHistoryItemsOrderBy,
  TransactionLayout,
} from 'common/types/globalTypes';
import { dateConvertions, dateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import {
  Supplier,
  SupplierVariables,
} from 'settings/account/supplier/view/__generated__/Supplier';
import {
  Traveler,
  TravelerVariables,
} from 'settings/account/Travelers/view/__generated__/Traveler';
import { ApprovalHistory_approvalHistory } from '../__generated__/ApprovalHistory';
import { userApproval_userApproval } from '../__generated__/userApproval';
import { TransactionEdit } from '../InvoiceList/TransactionEdit';
import { isLayoutPaycycle, isVendorLink } from '../utils';
import { useStyles } from './index.styles';
import { SupplierAutoComplete } from './SupplierAutoComplete';
import { TravelerAutoComplete } from './TravelerAutoComplete';
import { VendorCallout } from './VendorCallout';

const SUPPLIER_DETAILS = loader(
  '../../../settings/account/supplier/view/Supplier.graphql'
);
const TRAVELER = loader(
  '../../../settings/account/Travelers/view/Traveler.graphql'
);

interface InvoiceDetailedDataProps {
  data:
    | userApproval_userApproval
    | ApprovalHistory_approvalHistory
    | undefined
    | null;
  approvalOrderBy: ApprovalHistoryItemsOrderBy[];
  isHistory?: boolean;
}

export const InvoiceDetailedData: React.FC<InvoiceDetailedDataProps> = ({
  data,
  approvalOrderBy,
  isHistory = false,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { layoutType } = { ...data! };
  const titles =
    data?.layoutType && isLayoutPaycycle(data.layoutType)
      ? {
          desc1: 'Description',
          desc2: 'Bank Name',
          desc3: 'Account',
          date: 'Date',
          amount: 'Total',
        }
      : data?.layoutType && data?.layoutType === 'TIME_CARD'
      ? {
          desc1: 'Employee',
          desc2: 'Description',
          desc3: 'T/C Number',
          date: 'W/E Date',
          amount: 'Total',
        }
      : data?.layoutType === TransactionLayout.SIGNING_AMOUNT_DOCUMENT ||
        data?.layoutType === TransactionLayout.SIGNING_AMOUNT_DOCUMENT_VALUES
      ? {
          desc1: 'Name',
          desc2: 'Description',
        }
      : {
          desc1: 'Vendor',
          desc2: 'Description',
          desc3: 'Invoice Number',
          date: 'Invoice Date',
          amount: 'Invoice Total',
        };

  const isMiscellaneousApproval =
    data?.layoutType === TransactionLayout.MISCELLANEOUS_APPROVAL;

  const renderCustomTitle = () => (
    <Stack horizontal verticalAlign={'center'}>
      <Text variant="medium" className={styles.inputContainerLabel2}>
        {titles.desc1}
      </Text>
      <VendorCallout data={data!} />
    </Stack>
  );

  const showVendorLink = data?.layoutType
    ? isVendorLink(data.layoutType)
    : false;
  const isPettyCashFundLayout: boolean =
    data?.layoutType === TransactionLayout.PETTY_CASH ||
    data?.layoutType === TransactionLayout.PETTY_CASH_FUND;
  const isStrictlyPettyCash: boolean =
    data?.layoutType === TransactionLayout.PETTY_CASH;
  const isSigningAmountDocument =
    data?.layoutType === TransactionLayout.SIGNING_AMOUNT_DOCUMENT ||
    data?.layoutType === TransactionLayout.SIGNING_AMOUNT_DOCUMENT_VALUES;
  const isDocumentApproval =
    data?.layoutType === TransactionLayout.SIGNING_DOCUMENT ||
    isSigningAmountDocument;

  const showPeriod =
    !isMiscellaneousApproval &&
    !isDocumentApproval &&
    layoutType !== TransactionLayout.PO_SYSTEM_APPROVAL;
  const [showTransactionModal, setShowTransactionModal] =
    useState<boolean>(false);

  return (
    <Stack horizontal className={styles.inputContainers}>
      {isMiscellaneousApproval && (
        <MiscellaneousDataView data={data} approvalOrderBy={approvalOrderBy} />
      )}

      {!isMiscellaneousApproval && (
        <Stack grow tokens={{ childrenGap: 20 }}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={styles.flex50}>
              {showTransactionModal && (
                <TransactionEdit
                  transactionSigningId={data?.entityId}
                  setShowTransactionModal={setShowTransactionModal}
                  approvalOrderBy={approvalOrderBy}
                  approvalId={data?.id}
                  isHistory={isHistory}
                />
              )}
              <DataView
                title={titles.desc1}
                description={data?.description1}
                renderCustomTitle={renderCustomTitle}
                isTitleCustom={data?._isLookupNameApplied!}
                addLink={showVendorLink}
                onLinkClick={() => {
                  setShowTransactionModal(true);
                }}
              />
            </Stack.Item>
            <Stack.Item className={styles.flex50}>
              {isSigningAmountDocument && (
                <DataView
                  title="Weekly Limit"
                  currency={
                    data?.entityDocumentsByEntityId?.nodes[0]?.currency
                      ?.isoCode || ''
                  }
                  description={
                    data?.entityDocumentsByEntityId?.nodes[0]?.invoiceDocument
                      ?.controlTotalAmount
                  }
                  isAmount
                />
              )}
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item className={styles.flex50}>
              <DataView
                title={
                  isDocumentApproval && !isSigningAmountDocument
                    ? 'Contents'
                    : titles.desc2
                }
                description={data?.description2}
              />
            </Stack.Item>
            {isSigningAmountDocument && (
              <Stack.Item className={styles.flex50}>
                <DataView
                  title="Business Unit"
                  description={data?.businessUnit?.name}
                />
              </Stack.Item>
            )}
          </Stack>
          {isStrictlyPettyCash && (
            <DataView title="Reimbursed" description={data?.description6} />
          )}
          {data?.layoutType !== TransactionLayout.BATCH_TRANSACTION_APPROVAL &&
            !isDocumentApproval && (
              <DataView title={titles.desc3} description={data?.description3} />
            )}
          {data?.notesByEntityId.nodes.length && (
            <Stack grow={0.3}>
              <Text variant="medium" className={styles.inputContainerLabel}>
                Notes
              </Text>
              {data?.notesByEntityId.nodes.map((item) => (
                <Stack
                  key={item.id}
                  horizontal
                  className={styles.textNotesInputConatiner}
                  tokens={{ childrenGap: 3 }}
                >
                  <Icon
                    iconName="LocationDot"
                    style={{ paddingTop: 5 }}
                    className={commonStyles.colorThemePrimary}
                  />
                  <Text className={commonStyles.colorThemePrimary}>
                    {item.noteComment}
                  </Text>
                </Stack>
              ))}
            </Stack>
          )}
        </Stack>
      )}

      <Separator vertical />

      {!isDocumentApproval && (
        <Stack grow={0.3} tokens={{ childrenGap: 20 }}>
          <DataView
            title={titles.date}
            description={
              data?.date1 ? dateFormat(dateConvertions(data?.date1)) : '-'
            }
          />
          {!isMiscellaneousApproval && (
            <>
              <DataView
                title={titles.amount}
                description={data?.amount1}
                isAmount
                currency={data?.currency?.isoCode || ''}
              />
              {isPettyCashFundLayout && data?.amount2 && (
                <DataView
                  isAmount
                  title=""
                  description={data?.amount2}
                  currency={data?.currency?.isoCode || ''}
                />
              )}
              {data?.layoutType !==
                TransactionLayout.BATCH_TRANSACTION_APPROVAL && (
                <DataView
                  title="Business Unit"
                  description={data?.businessUnit?.name}
                />
              )}
            </>
          )}
        </Stack>
      )}
      {showPeriod && (
        <>
          <Separator vertical />
          <Stack grow={0.3} tokens={{ childrenGap: 20 }}>
            <DataView
              title="Period"
              description={data?.companyCorporatePeriod?._periodYear}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};

const MiscellaneousDataView: React.FC<InvoiceDetailedDataProps> = ({
  data,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const { data: supplierDetailsData } = useQuery<Supplier, SupplierVariables>(
    SUPPLIER_DETAILS,
    {
      variables: {
        id: data?.entityId!,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const { data: travelerData, loading: travelerDataLoading } = useQuery<
    Traveler,
    TravelerVariables
  >(TRAVELER, {
    variables: {
      id: data?.entityId!,
    },
    skip: !data?.entityId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  return (
    <Stack grow={0.3}>
      {data?.userEntityApp?.linkType !== 'SUPPLIER' && (
        <Text variant="medium" className={styles.inputContainerLabel}>
          Description
        </Text>
      )}
      {data?.userEntityApp?.linkType === 'SUPPLIER' ? (
        <Stack>
          <Text variant="medium" className={styles.textInputConatiner}>
            <SupplierAutoComplete
              name="supplierId"
              supplier={supplierDetailsData?.supplier}
              label="Supplier"
              placeholder="Supplier"
              showAddIcon
              disabled={true}
              required
            />
          </Text>
        </Stack>
      ) : data?.userEntityApp?.linkType === 'TRAVELER' ? (
        <Stack>
          <Text variant="medium" className={styles.textInputConatiner}>
            <TravelerAutoComplete
              name="travelerId"
              traveler={travelerData}
              label="Traveler"
              placeholder="Traveler"
              showAddIcon
              disabled={true}
              required
            />
          </Text>
        </Stack>
      ) : (
        <Text className={styles.textInputConatiner}>{data?.description1}</Text>
      )}
      <Text className={styles.textInputConatiner}>{data?.description2}</Text>
      <Text className={styles.textInputConatiner}>{data?.description3}</Text>
      <Text className={styles.textInputConatiner}>{data?.description4}</Text>
      <Text className={styles.textInputConatiner}>{data?.description5}</Text>
      {data?.notesByEntityId.nodes.length && (
        <Stack grow={0.3}>
          <Text variant="medium" className={styles.inputContainerLabel}>
            Notes
          </Text>
          {data?.notesByEntityId.nodes.map((item) => (
            <Stack
              key={item.id}
              horizontal
              className={styles.textInputConatiner}
              tokens={{ childrenGap: 3 }}
            >
              <Icon
                iconName="LocationDot"
                style={{ paddingTop: 5 }}
                className={commonStyles.colorThemePrimary}
              />
              <Text className={commonStyles.colorThemePrimary}>
                {item.noteComment}
              </Text>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

interface DataViewProps {
  /**  Add your custom title   */
  title?: string;
  /**  Add your custom description    */
  description?: string | null;
  /**  Add your custom description    */
  isAmount?: boolean;
  /**  Add your custom description    */
  currency?: string;
  /** Render Custom Title as and when required */
  renderCustomTitle?: () => JSX.Element | undefined;
  isTitleCustom?: boolean;
  addLink?: boolean;
  onLinkClick?: () => void;
}

export const DataView: React.FC<DataViewProps> = ({
  title,
  description,
  isAmount,
  currency,
  renderCustomTitle,
  isTitleCustom = false,
  addLink = false,
  onLinkClick,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  const descriptionStyles = clsx({
    [styles.textInputConatiner]: true, //always applies
    [styles.poNumber]: title === 'Number',
    [commonStyles.semibold]: title === 'Number',
    [commonStyles.colorThemePrimary]: title === 'Number',
  });

  return (
    <Stack className={styles.twoInputContainer}>
      {isTitleCustom ? (
        <Stack>{renderCustomTitle?.()}</Stack>
      ) : (
        <Text variant="medium" className={styles.inputContainerLabel}>
          {title}
        </Text>
      )}
      {isAmount && description ? (
        <Stack horizontal>
          <Text className={styles.currency}>{currency || ''}</Text>
          <AmountTextView value={description} style={{ fontWeight: 'bold' }} />
        </Stack>
      ) : addLink ? (
        <Stack style={{ marginLeft: 20 }}>
          <StyledLink onLinkClick={onLinkClick} linkText={description ?? ''} />
        </Stack>
      ) : (
        <Text className={descriptionStyles} variant="mediumPlus">
          {description}
        </Text>
      )}
    </Stack>
  );
};
