import { dateConvertions } from 'common/utils/dateFormats';
import { EntityDocumentInput } from 'common/types/globalTypes';
import { InvoiceDetails } from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceDetails';
import { ACCOUNTING_INITIAL_VALUES, INVOICE_INITIAL_VALUES } from './constant';
import { AttachableDataType, TransactionSigningValues } from 'common/components/Modules/TransactionEdit/types';

interface DefaultValueProps {
  isNew: boolean;
  invoiceDetailsData: InvoiceDetails | undefined;
}

export const getDefaultValues = (props: DefaultValueProps) => {
  const { isNew, invoiceDetailsData } = { ...props };
  let defaultValues: TransactionSigningValues = { ...INVOICE_INITIAL_VALUES };
  if (invoiceDetailsData?.invoice && !isNew) {
    if (invoiceDetailsData.invoice._isUpdatable) {
      defaultValues = {
        ...invoiceDetailsData.invoice,
        invoiceDate: invoiceDetailsData.invoice.invoiceDate
          ? dateConvertions(invoiceDetailsData.invoice.invoiceDate!)
          : null,
        layoutType: invoiceDetailsData.invoice.transactionType?.layoutType!,
        invoiceDistributions:
          invoiceDetailsData.invoice.invoiceDistributions.nodes.length > 0
            ? [
                ...invoiceDetailsData.invoice.invoiceDistributions.nodes,
                { ...ACCOUNTING_INITIAL_VALUES },
              ]
            : [ACCOUNTING_INITIAL_VALUES],
      };
    } else {
      defaultValues = {
        ...invoiceDetailsData.invoice,
        layoutType: invoiceDetailsData.invoice.transactionType?.layoutType!,
        invoiceDistributions:
          invoiceDetailsData?.invoice?.invoiceDistributions?.nodes,
      };
    }
  }
  return defaultValues as TransactionSigningValues;
};

export const formatEntityDocuments = (documents: EntityDocumentInput[]) => {
  if (!documents.length) return [];
  return documents.map((doc) => {
    const {
      id,
      comment,
      entityId,
      fileReference,
      fileIndexInformation,
      entityDocumentTypeId,
      indexName,
      indexDescription,
      indexTransactionDate,
      indexAmount,
      indexCurrencyId,
      documentAppliedAmount,
      documentStatusExplanation,
      isAppliedAmountRetired,
      isAppliedAmountOverage,
      documentRetiredAmount,
    } = { ...doc };
    return {
      id,
      comment,
      entityId,
      fileReference,
      fileIndexInformation,
      entityDocumentTypeId,
      indexName,
      indexDescription,
      indexTransactionDate,
      indexAmount,
      indexCurrencyId,
      documentAppliedAmount,
      documentStatusExplanation,
      isAppliedAmountRetired,
      isAppliedAmountOverage,
      documentRetiredAmount,
    };
  });
};

export const getPOInvoiceSchedules = (documents: AttachableDataType[]) => {
  if (!documents.length) return [];
  const tempPOInvoiceSchedulesArray = documents.map(
    (ele) => ele.poInvoiceSchedules
  );
  if (!!tempPOInvoiceSchedulesArray.length) {
    return tempPOInvoiceSchedulesArray.reduce(
      (accumulator, currentValue) => accumulator?.concat(currentValue!),
      []
    );
  } else return [];
};
